@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0px;
    background: #ECEEFE;
}

.container-display {
    padding-left: 16%;
    height: 80vh;
    max-width: 1200px;
    margin: auto;

    .MuiPaper-root {
        background: #ECEEFE;
        width: 100%;
        box-shadow: none;
        padding-bottom: 0em;
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
    }

    .MuiTableContainer-root {
        border-radius: 16px 16px 0 0;
        background: white;
        height: 76%;
        box-shadow: 0px 0px 2px 0px #858585;
        margin-top: 1em;
    }

    .MuiTablePagination-root {
        background: #ECCC1E; // width: 96%;
        margin: auto;
        border-radius: 0 0 16px 16px;
        box-shadow: 0px 0px 2px 0px #858585;
        color: white;
    }
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
    font-size: 12px !important;
}

.fields-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.column {
    display: flex;
    flex-direction: column;
}

#save {
    background: #5154C3;
    min-width: 120px;
}

@media only screen and (min-width: 1000px) {
    .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
        font-size: 16px !important;
    }

    #save {
        background: #5154C3;
        min-width: 160px;
    }

}