.login-container {
    background: #5254C2;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.css-binzgt {
    margin-top: 0 !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 25%;

    img {
        width: 80%;
        max-width: 480px;
    }
}

.css-1999axt-MuiAvatar-root {
    background-color: #ECCC1E !important;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
    background-color: #ECCC1E !important;
}