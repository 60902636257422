.hidden {
    background-color: red;
    display: none;
}

.left-list-container {
    width: 16%;
    height: 100%;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    -webkit-box-shadow: 2px 0px 10px 0px #C7C7C7;
    box-shadow: 2px 0px 10px 0px #C7C7C7;
    background: #5154C3;

    img {
        width: 100%;
        padding-top: 2em;
    }

    .css-jpln7h-MuiTabs-scroller {
        display: flex;
        justify-content: center;
    }

    .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
        text-transform: unset !important;
    }

    .MuiButtonBase-root {
        color: white;
    }

    .MuiButtonBase-root.Mui-selected {
        color: #ECCC1E !important;
        width: 100%;
    }

    .MuiTabs-flexContainer {
        align-items: self-start;
        width: 100%;
    }

    @media only screen and (max-width: 1000px) {
        span {
            font-size: 12px;
        }
    }
}