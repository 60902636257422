.textarea-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .textarea-form {
        width: 25ch;
        padding: 16.5px 14px;
        height: 4em;
        border-radius: 4px;
    }

}