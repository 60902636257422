.search-container {
    width: 80%;
    height: 100%;

    .css-poguvo {
        box-shadow: 0px 0px 2px 0px #858585;
        background: white;
        width: 100% !important;
        height: 100%;
        border: 1px solid #5154C3;
    }
}