.hours-container {
    padding-left: 16%;
    padding-bottom: 2em;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: scroll;
    height: 80vh;

    #valid-week {
        background: #5154C3;
    }

    #remove-week {
        background-color: red;
    }

    .css-4jnixx-MuiStack-root {
        padding-top: 0 !important;
    }
}