.list-candidats-container {}

.bck {
    background-color: red;
}

.search-create-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
}