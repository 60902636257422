.button-display {
    width: 16%;
    padding: 0;

    h2 {
        color: white;
        font-size: 12px;
    }
}

@media only screen and (min-width: 1000px) {
    .button-display h2 {
        font-size: 14px !important;
    }
}