.btns-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 76%;
    gap: 1em;

    .css-5164jb-MuiButtonBase-root-MuiButton-root {
        font-weight: unset !important;
        font-size: unset !important;
        line-height: unset !important;
        letter-spacing: unset !important;
        text-transform: unset !important;
        min-width: unset !important;
    }

    button {
        width: 100%;
        background: #5154C3;
        -webkit-box-shadow: 0px 0px 2px 0px #858585;
        box-shadow: 0px 0px 2px 0px #858585;

        &:hover {
            background: #5154C3;
            opacity: 0.8;
        }
    }
}