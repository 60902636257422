.modal-form-container {
    text-align: center;
    height: 56px;
    width: 56px;
    display: flex;
    background: #5154C3;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px #858585;

    i {
        color: white;
        font-size: larger;
    }

    &:hover {
        background-color: white;
        border: 1px solid #5154C3;

        i {
            color: #5154C3;
        }
    }
}