.container-week-form {
    border: 0;
    border-radius: 16px;
    gap: 0.4em;
    min-width: 300px;
    padding: 0.8em;
    background-color: white;
    box-shadow: 0px 0px 2px 0px #858585;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 300px;

    h2,
    p {
        color: #5154C3;
    }

    .hs-inputs-container {
        gap: 0.4em;
    }

    .add-remove-week-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
            background: #5154C3;
            ;
            border: 0;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            box-shadow: 0px 0px 2px 0px #858585;
            color: white;
        }
    }
}