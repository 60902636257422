.time-week-form-container {
    padding-top: 2em;
    overflow: scroll;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .valid-remove-container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1em;
        padding: 0.1em;

        button {
            width: 180px;
        }
    }
}

.cards-week-container {
    display: flex;
    align-items: flex-start;
    gap: 1em;
    padding: 1em;

    .shadow-card-week {
        min-width: 300px;
        height: 300px; // size in adequation with number fields in card week because card dynamik
        background: aquamarine;
        border-radius: 16px;

        button {
            width: 100%;
            height: 100%;
            background: #ECCC1E !important;
            opacity: 1;
            border: 0;
            border-radius: 16px;
            font-size: 36px;
            box-shadow: 0px 0px 2px 0px #858585;
            color: white;

            &:hover {
                background: #5154C3;
                opacity: 0.8;

            }
        }
    }
}