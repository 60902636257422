.preview-btns-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1em;
    padding: 0 1em 1em;
    height: 100%;
    background: #ECEEFE;
    justify-content: space-between;

    .preview-container {
        background: white;
        -webkit-box-shadow: 0px 0px 2px 0px #858585;
        box-shadow: 0px 0px 2px 0px #858585;
        padding: 1em;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        justify-content: space-between;

        p {
            text-transform: uppercase;
            font-family: Arial, Helvetica, sans-serif;
            display: flex;
            align-items: center;
            gap: 1em;

            svg {
                color: #5154C3;
            }
        }

        .ellipsis {
            text-overflow: ellipsis;
            width: 180px;
            display: inline-block;
            align-items: center;
            gap: 1em;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}